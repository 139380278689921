import React, { useState, useEffect, memo, useContext } from "react";
import axios from "axios";
import { GrClose } from "react-icons/gr";
import { HiOutlineRefresh } from "react-icons/hi";
import { BsFillMicFill } from "react-icons/bs";
import Loader from "../modules/App/LayoutComponents/Loader/Loader";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { LANGUAGES_LIST } from "../common/metadata";
import Dialog from "../common/Dialog";
import { PiSpeakerSimpleXBold, PiSpeakerHighDuotone } from "react-icons/pi";
import { MicListingComponent } from "./context_chatgpt/MicListingComponent";
import { ConvoHistory } from "./utils";

let chartName = "";

const Assistant = ({
  sidebarStatus,
  setSidebarStatus,
  convoId,
  setconvoId,
}) => {
  const [input, setInput] = useState("");
  const [response, setResponse] = useState("");
  const [loader, setloader] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  // toggle speaker
  const [isListing, setisListing] = useState(false);

  // language state
  const [lang, setlang] = useState("");

  // text-to-speak
  const { speak, cancel } = useSpeechSynthesis({
    onEnd: () => {
      setisListing(false);
    },
  });

  // chat gpt api key
  const [key, setkey] = useState(
    process.env.REACT_APP_CHATGPT_SUMMARIZE_API_KEY
  );

  // error message
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [toggleMic, settoggleMic] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const systemMessage = {
    role: "assistant",
    content:
      "Summarize the provided data and create concise summaries or key points.",
  };

  const closeSidebar = () => {
    setSidebarStatus(!sidebarStatus);
    setconvoId("");
    chartName = "";
  };
  const handleRefresh = () => {
    // Reset chat history by setting it to an empty array
    setChatHistory([]);
  };

  const handleSubmit = async (e) => {
    if (!key) {
      seterrorMessage("Please provide your API Key.");
      seterror(true);
      return;
    }

    if (!input) {
      seterrorMessage(
        "Input cannot be left blank. Try asking Your Personal AI Assistant a question."
      );
      seterror(true);
      return;
    }
    const userMessage = {
      role: "user",
      content: input,
    };
    if (!chartName) {
      chartName = input;
    }
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage,
        {
          role: userMessage.role,
          content: lang ? `In ${lang} ${input}` : input,
        },
      ],
    };

    try {
      setloader(true);
      // https://api.openai.com/v1/chat/completions
      const response = await axios.post(
        "https://genai-001.openai.azure.com/openai/deployments/gpt-4-32k/chat/completions?api-version=2023-07-01-preview",
        apiRequestBody,
        {
          headers: {
            // Authorization: "Bearer " + key,
            "api-key": process.env.REACT_APP_CHATGPT_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      const assistantMessage = {
        role: "assistant",
        content: response.data.choices[0].message.content,
      };

      if (response.data) {
        const RequestBody = {
          _id: convoId,
          chatId: response.data?.id,
          chartName: chartName,
          conversationId: convoId,
          createdTime: response.data?.created,
          messages: [userMessage, assistantMessage],
          usage: response.data?.usage,
        };

        ConvoHistory(RequestBody)
          .then((res) => {})
          .catch((error) => {});
      }

      // Update the chat history with both user and assistant messages
      setChatHistory([...chatHistory, userMessage, assistantMessage]);
      if (response.data.choices[0].message.content) {
        setloader(false);
      }
      // Clear the input field
      setInput("");
    } catch (error) {
      setloader(false);
    }
  };

  // listning with user mic
  const [isMicON, setisMicON] = useState(false);

  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setInput(result);
    },
  });

  function getuserMicData() {
    setisMicON(true);
    if (!listening) {
      listen({ continuous: true });
    }
  }

  function StopMic() {
    setisMicON(false);
    stop();

    if (input) {
      handleSubmit();
    }
  }

  return (
    <>
      <div
        className={
          "fixed bottom-0 right-0 z-50 w-full backdrop-blur-sm h-screen flex justify-end " +
          (sidebarStatus ? "translate-x-0" : "translate-x-[120%]")
        }
      >
        <div
          className={
            "sm:w-[550px] w-full h-screen bg-[#fbfbfb] relative duration-200 ease-in-out " +
            (sidebarStatus ? "translate-x-0" : "translate-x-[120%]")
          }
        >
          <div className="w-full h-full flex flex-col gap-2">
            <div className="w-full flex items-center justify-between px-5 py-4 border-b-[1.5px] border-gray-400">
              <p className="text-[20px] font-medium">
                HaiX Gen - Your Personal AI Assistant{" "}
              </p>
              <button>
                <GrClose onClick={closeSidebar} />
              </button>
            </div>
            <p className="text-sm px-4">
              Use this HaiX AI Assistant for general prompts like you would do
              with chatgpt, google bard. (e.g, write a post on quantum physics
              etc)
            </p>
            <div className="w-full h-full  ">
              <div className="w-full h-full px-5 ">
                <div className="w-full">
                  <div className="w-full my-2 flex items-center justify-between px-1">
                    <div className="flex justify-start items-center">
                      <button
                        alt="Default Prompt"
                        className="w-[30px] h-[30px] rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200"
                      >
                        <HiOutlineRefresh
                          className="text-[14px]"
                          onClick={handleRefresh}
                        />
                      </button>
                      <button
                        alt="Default Prompt"
                        className="w-[30px] h-[30px] mx-2 rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200"
                      >
                        <BsFillMicFill
                          onClick={() => settoggleMic(true)}
                          className="text-[14px]"
                        />
                      </button>
                    </div>
                    <div className="flex justify-center items-center">
                      <label className="font-bold mr-1" htmlFor="API key">
                        API key:{" "}
                      </label>
                      <input
                        type="password"
                        placeholder="Enter API key"
                        name="key"
                        id="key"
                        defaultValue={key}
                        onChange={(e) => setkey(e.target.value)}
                        className="w-40 max-h-7 text-xs rounded"
                      />
                    </div>
                  </div>
                  <textarea
                    className="w-full min-h-[220px] max-h-[250px] py-2 rounded-lg border-[0.5px] border-blue-500 px-2.5 text-[14px]"
                    name="prompt"
                    alt="Prompt_field"
                    value={input}
                    onChange={handleChange}
                    placeholder="Ask a question..."
                  ></textarea>
                  <div className="flex justify-between items-center">
                    <button
                      className="w-max h-full px-4 py-2 text-[16px] bg-gradient-to-r from-[#01A0F9] via-[#058388] to-[#09C778]  text-white rounded"
                      onClick={handleSubmit}
                    >
                      Ask a question
                    </button>
                    <div className="">
                      <select
                        className="form-select rounded"
                        onChange={(e) => {
                          setlang(e.target.value);
                        }}
                      >
                        <option value={null}>Select Your Language</option>
                        {LANGUAGES_LIST.map((item) => (
                          <option key={item.code} value={item.language}>
                            {item.language}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-full h-[calc(100%-400px)] my-2 overflow-y-auto">
                  {/* Display the chat history with both user and assistant messages */}
                  {loader && <Loader />}
                  {chatHistory.map((message, index) => (
                    <div
                      key={index}
                      className={
                        message.role === "user"
                          ? "message bg-green-100"
                          : "message my-4"
                      }
                    >
                      <div className="my-1 capitalize font-bold">
                        {message?.role}:{" "}
                      </div>
                      <div className="flex justify-between items-center">
                        {message.content}{" "}
                        {message.role === "user" && (
                          <button
                            alt="Default Prompt"
                            className="w-[30px] h-[30px] rounded-full bg-gray-100 mr-4 flex items-center justify-center hover:bg-gray-200"
                          >
                            {isListing ? (
                              <PiSpeakerHighDuotone
                                className="text-[14px]"
                                onClick={() => {
                                  cancel();
                                  setisListing(false);
                                }}
                              />
                            ) : (
                              <PiSpeakerSimpleXBold
                                className="text-[14px]"
                                onClick={() => {
                                  speak({
                                    text: chatHistory[index + 1].content,
                                  });
                                  setisListing(true);
                                }}
                              />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        title={"Alert"}
        body={errorMessage}
        showDialog={error}
        positiveButton1={{ text: "OK", callback: () => seterror(false) }}
        setShowDialog={seterror}
      />
      <Dialog
        showDialog={toggleMic}
        body={
          <MicListingComponent
            isMicON={isMicON}
            setisMicON={setisMicON}
            start={getuserMicData}
            stop={StopMic}
          />
        }
        footer={{
          text: "close",
          callback: () => {
            settoggleMic(false);
            stop();
          },
        }}
      />
    </>
  );
};

export default Assistant;
