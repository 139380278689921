import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from 'react';
import {
  UserProfileContext,
  fetchUserProfile,
  saveSocialMediaToken,
  updateUserProfile,
} from '../../store/context/profile.context';

import AppNavigator from './AppNavigator';
import { ChatgptProvider } from '../../chatgpt_summarize/context_chatgpt/ChatgptContext';
import ChatgptSummary from '../../chatgpt_summarize/ChatgptSummary';
import Chatgpt_API from '../../chatgpt_summarize/Chatgpt_API';
// import FooterTestimonial from '../../common/FooterTestimonial';
import Header from './LayoutComponents/Header';
import { HeaderProvider } from './LayoutComponents/header/HeaderContext';
import { PROFILE_OPERATION_TYPE } from '../../common/constants';
import Sidebar from './LayoutComponents/Sidebar';
// import Testimonial from '../../common/Testimonial';
import { ToastContainer } from 'react-toastify';
import WelcomeBanner from './LayoutComponents/WelcomeBanner';
import { initFacebook } from '../../services/facebookApi/initFacebookSDK.service';
import { setToken } from '../../services/common/authToken';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

function AppBase() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { user, getAccessTokenSilently } = useAuth0();
  const [profileInfo, setProfileInfo] = useState();
  const [isFooterDisplay, setIsFooterDisplay] = useState(false);

  // Triggered on route change to reset scroll position
  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]);

  useEffect(() => {
    const initActions = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
        refreshUserProfile();
        // init fb sdk
        if (!window.FB) {
          initFacebook();
        }
      } catch (error) {
        // TODO: show errors as alerts to user UI
        console.error(
          'Error while initializing user data. Check logs for more info.',
          error,
        );
      }
    };
    initActions();
  }, [user]);

  /**
   * @returns uses the callback setProfileInfo to set the data in
   * profile info context and update the data.
   */
  const refreshUserProfile = () =>
    fetchUserProfile(
      user.name,
      setProfileInfo,
      PROFILE_OPERATION_TYPE.getProfileInfo,
    );

  /**
   *
   * @param {any} data dynamic data it hold based on the social media login we are performing
   * @returns uses the callback setProfileInfo to set the data in
   * profile info context and update the data.
   */
  const saveUserProfile = (data) =>
    updateUserProfile(data, setProfileInfo, PROFILE_OPERATION_TYPE.updateInfo);

  /**
   *
   * @param {*} data
   * @param {*} socialMediaType uses the social media type to differentiate
   * which social media(facebook, instagram, linkedIn and facebookAds) the user is loggin in
   * @returns uses the callback setProfileInfo to set the data in
   * profile info context and update the data.
   */
  const socialMediaSaveToken = (data, socialMediaType) =>
    saveSocialMediaToken(
      data,
      setProfileInfo,
      PROFILE_OPERATION_TYPE.socialMediaSaveToken,
      socialMediaType,
    );

  return (
    <React.StrictMode>
      <UserProfileContext.Provider
        value={{
          profileInfo,
          refreshUserProfile,
          saveUserProfile,
          socialMediaSaveToken,
          setProfileInfo,
        }}
      >
        {/* header title */}
        <HeaderProvider>
          <ChatgptProvider>
            {/* chatgpt summarizer component */}
            <div
              className={`flex h-[100vh] overflow-hidden  bg-primary bg-opacity-5`}
            >
              {/* Sidebar */}
              <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
              {/* Content area */}
              <div
                // onClick={() => setSidebarOpen(false)}
                className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
              >
                {/*  Site header */}
                <Header
                  sidebarOpen={sidebarOpen}
                  user={user}
                  setSidebarOpen={setSidebarOpen}
                />
                <main>
                  {/* removed by Daniel the property max-w-9xlto adjust the full screen size for 4k screens */}
                  <div className="px-2 py-3 w-full mx-auto">
                    {/* Welcome banner */}
                    <WelcomeBanner user={user} />
                    <AppNavigator />
                  </div>
                </main>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <Chatgpt_API />
            </div>
            <ChatgptSummary />
          </ChatgptProvider>
        </HeaderProvider>

        {/* chatgpt summarizer component */}
        {/* react query dev tools */}
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
      </UserProfileContext.Provider>
      {/* <Testimonial /> */}
    </React.StrictMode>
  );
}

export default AppBase;
