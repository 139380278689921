import React, { Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./LayoutComponents/Loader/Loader";
import { routes } from "../../common/routes";
import { UserProfileContext } from "../../store/context/profile.context";

function AppNavigator() {
  const profileContext = useContext(UserProfileContext);

  const { profileInfo = {} } = profileContext;

  return (
    <Routes>
      {routes.map((page) => {
        const { component: Component, exact, name, path, index } = page;
        if (
          name == "PortfolioInsightsCompititor" &&
          !profileInfo?.CompetitorIntelligence?.subscription_requested
        ) {
          return null;
        } else if (
          name == "PortfolioInsights" &&
          !profileInfo?.CompanyMarketIntelligence?.subscription_requested
        ) {
          return null;
        } else if (
          name == "Linkedin Public Page Insights" &&
          !profileInfo?.LinkedInSocialListening?.subscription_requested
        ) {
          return null;
        } else if (
          name == "Linkedin Insights" &&
          !profileInfo?.LinkedInOwnAccountIntelligence?.subscription_requested
        ) {
          return null;
        } else if (
          name == "Lead Generation" &&
          !profileInfo?.LeadIQInsights?.subscription_requested
        ) {
          return null;
        } else if (
          name == "SocialMedia" &&
          !profileInfo?.LeadIQInsights?.subscription_requested
        ) {
          return null;
        } else if (
          name == "Trust Pilot" &&
          !profileInfo?.TrustpilotIntelligence?.subscription_requested
        ) {
          return null;
        } else if (
          name == "Job Insights" &&
          !profileInfo?.LeadIQInsights?.subscription_requested
        ) {
          return null;
        } else if (
          name == "genai summary" &&
          !profileInfo?.GenAISocialInsights?.subscription_requested
        ) {
          return null;
        } else if (
          name == "ActionableInsights" &&
          !profileInfo?.SocialListening?.subscription_requested
        ) {
          return null;
        } else if (
          name == "Company Details" &&
          !profileInfo?.CompanyMarketIntelligence?.subscription_requested
        ) {
          return null;
        } else {
          return (
            <Route
              path={path}
              element={
                <Suspense
                  fallback={
                    <Loader message="Our AI is generating insights from millions of social media data " />
                  }
                >
                  <Component />
                </Suspense>
              }
              exact={exact}
              key={name}
              index={index}
            />
          );
        }
      })}
    </Routes>
  );
}

export default React.memo(AppNavigator);
