//search key properties
import { SOCIAL_MEDIA_METADATA } from "../../common/metadata";
import { v4 as uuidv4 } from "uuid";

export const searchTerm = {
  masterSearch: "name",
  searchTermTwitter: "twitterHandler",
  searchTermFacebook: "facebookHandler",
  searchTermYoutube: "youtubeHandler",
  searchTermInstagram: "instagramHandler",
  searchTermReddit: "redditHandler",
  searchTermNews: "newsHandler",
  searchTermLinkedIn: "linkedinPublicPageHandler",
  searchTermTikTok: "tiktokHandler",
  searchTermTrustPilot: "trustPilotcompanyName",
};

// alert key properties
const ALERT_KEYS = {
  keywordDetectionAlert: {
    alertTwitter: { key: "tweetKeyword", frequency: "twitterKeywordfrequency" },
    alertFacebook: {
      key: "facebookHandlerKeyword",
      frequency: "facebookHandlerKeywordfrequency",
    },
    alertYoutube: {
      key: "youtubeKeyword",
      frequency: "youtubeKeywordfrequency",
    },
    alertInstagram: {
      key: "instagramKeyword",
      frequency: "instagramKeywordfrequency",
    },
    alertReddit: { key: "redditKeyword", frequency: "redditKeywordfrequency" },
    alertNews: { key: "newsKeyword", frequency: "newsKeywordfrequency" },
    alertTikTok: { key: "tiktokKeyword", frequency: "tiktokKeywordfrequency" },
    alertLinkedIn: {
      key: "linkedinKeyword",
      frequency: "linkedinKeywordfrequency",
    },
  },
  averageSentimentDropAlert: {
    alertTwitter: {
      key: "tweetAverageSentimentValue",
      frequency: "twitterSentimentDropfrequency",
    },
    alertFacebook: {
      key: "facebookAverageSentimentValue",
      frequency: "facebookSentimentDropfrequency",
    },
    alertYoutube: {
      key: "youtubeAverageSentimentValue",
      frequency: "youtubeSentimentDropfrequency",
    },
    alertInstagram: {
      key: "instagramAverageSentimentValue",
      frequency: "instagramSentimentDropfrequency",
    },
    alertReddit: {
      key: "redditAverageSentimentValue",
      frequency: "redditSentimentDropfrequency",
    },
    alertNews: {
      key: "newsAverageSentimentValue",
      frequency: "newsSentimentDropfrequency",
    },
    alertTikTok: {
      key: "tiktokAverageSentimentValue",
      frequency: "tiktokSentimentDropfrequency",
    },
    alertLinkedIn: {
      key: "linkedinAverageSentimentValue",
      frequency: "linkedinSentimentDropfrequency",
    },
  },
  countSentimentAlert: {
    alertTwitter: {
      key: "twitterCountSentiment",
      frequency: "twitterCountfrequency",
      threshold: "threshold",
    },
    alertFacebook: {
      key: "facebookCountSentiment",
      frequency: "facebookCountfrequency",
      threshold: "threshold",
    },
    alertYoutube: {
      key: "youtubeCountSentiment",
      frequency: "youtubeCountfrequency",
      threshold: "threshold",
    },
    alertInstagram: {
      key: "instagramCountSentiment",
      frequency: "instagramCountfrequency",
      threshold: "threshold",
    },
    alertReddit: {
      key: "redditCountSentiment",
      frequency: "redditCountfrequency",
      threshold: "threshold",
    },
    alertNews: {
      key: "newsCountSentiment",
      frequency: "newsCountfrequency",
      threshold: "threshold",
    },
    alertTikTok: {
      key: "tiktokCountSentiment",
      frequency: "tiktokCountfrequency",
      threshold: "threshold",
    },
    alertLinkedIn: {
      key: "linkedinCountSentiment",
      frequency: "linkedinCountfrequency",
      threshold: "threshold",
    },
  },
};

export const ALERT_KEYS_TYPE = {
  keywordDetection: "keywordDetectionAlert",
  averageSentimentDrop: "averageSentimentDropAlert",
  countSentimentAlert: "countSentimentAlert",
};

export const ALERT_KEYS_VALUE = {
  keywordDetectionAlert: "Keyword Detection",
  averageSentimentDropAlert: "Sentiment Drop",
  countSentimentAlert: "Count Sentiment Limit",
};

export const FrequencyListNames = {
  hourly: "Hourly",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
};

// category key properties
const CATEGORY_MAPS = {
  categoryTwitter: "twitterHandler",
  categoryFacebook: "facebookHandler",
  categoryInstagram: "instagramHandler",
  categoryYoutube: "youtubeHandler",
  categoryReddit: "redditHandler",
  categoryNews: "newsHandler",
  categoryTikTok: "tiktokHandler",
  categoryLinkedIn: "linkedinHandler",
  categoryTrustPilot: "trustPilotcompanyName",
};

export const profileResponseTransformer = (data) => {
  const { companies = [], ...profileKeys } = data;
  const profileObject = { ...profileKeys };
  let tempAlertMap;
  delete profileObject["Account name"];
  profileObject.user = profileKeys.user;
  profileObject.accountname = profileKeys["Account name"];
  // Parse Companies
  const companiesCollection = companies.map((company) => {
    const compCollector = {};
    const mediaSearchKeys = {};
    const { alerts = [], categories = [] } = company;
    compCollector.masterSearch = company[searchTerm.masterSearch];
    compCollector.id = company.id;
    compCollector.language = company["lang"];
    compCollector.tiktokType = company?.tiktokSearches
    compCollector.instagramType = company?.instagramSearches
     compCollector.tiktokOptions = company?.tiktokType
    compCollector.instagramOptions = company?.instagramType
    compCollector.youtubeOptions = company?.youtubeType
    compCollector.youtubeType = company?.youtubeSearches
    compCollector.socialInfo = SOCIAL_MEDIA_METADATA.map((item) => {
      tempAlertMap = [];
      const mediaCollector = {};
      mediaCollector.type = item["icon"];
      mediaCollector.id = uuidv4();
      mediaSearchKeys[item.label.toLowerCase()] =
        company[searchTerm[`searchTerm${item.label}`]];
      mediaCollector.searchTerm =
        company[searchTerm[`searchTerm${item.label}`]];

      // alerts code start
      for (const alert of alerts) {
        const alertType = alert["alertType"];
        if (alert[ALERT_KEYS[alertType][`alert${item.label}`]?.key]) {
          const alertCollector = {};
          alertCollector.type = alertType;
          alertCollector.id = uuidv4();
          try {
            alertCollector.keyword =
              alert[ALERT_KEYS[alertType][`alert${item.label}`]["key"]];
            alertCollector.frequency =
              alert[ALERT_KEYS[alertType][`alert${item.label}`]["frequency"]];
            if (alertType === ALERT_KEYS_TYPE.countSentimentAlert)
              alertCollector.threshold =
                alert[ALERT_KEYS[alertType][`alert${item.label}`]["threshold"]];
          } catch (e) {
            console.error("error occured");
          }
          tempAlertMap.push(alertCollector);
        }
      }
      mediaCollector.alerts = [...tempAlertMap];
      // alerts code end
  // ;
      // categories code start
      if (categories[CATEGORY_MAPS[`category${item.label}`]]) {
      
        mediaCollector.categories = categories[
          CATEGORY_MAPS[`category${item.label}`]
        ].map((category) => ({
          category: Object.keys(category)[0],
          id: uuidv4(),
          keyword: category[Object.keys(category)[0]],
          tiktokType:"",
          instagramType:"",
          youtubeType:"",
        }));
      }
 
      // categories code end
      return mediaCollector;
    });
    compCollector.mediaSearchKeys = mediaSearchKeys;
    return compCollector;
  });
  profileObject.companies = companiesCollection;
  profileObject.companyGroup = profileKeys["company_group"];
  //TODO:: add other params needed throughout the app for userprofile
  return profileObject;
};

/* Sample Schema */
// {
//   type: "fb-simple",
//   searchTerm: "searchterm1",
//   alerts: [
//     {
//       type: "keywordDetection",
//       keyword: "test tewnety tert",
//       frequency: "daily",
//     },
//     {
//       type: "sentimentDrop",
//       keyword: "tsrs sdfsf fsdf",
//       frequency: "monthly",
//     },
//     {
//       type: "countSentimentAlert",
//       keyword: "crazy test",
//       frequency: "yearly",
//     },
//   ],
//   categories: [
//     { category: "orbutuary", keyword: "one nice skrty" },
//     { category: "gate keeping", keyword: "suel fonarty" },
//   ],
// }
/* Sample Schema */
