/**
 * This is temporary fix to make the side show active on naviigate page
 * right now `useLocation` hook of react-router-dom is returning `null`
 *
 * instead of location hook i have added window.location
 * later on window.location will be revomed. after the fixing the `null` problem of location.
 *
 * For now window.location is temporary fix.
 *
 * -MrDark (Sarfaraj Shah)
 *
 */

import { NavLink, matchPath, resolvePath, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { SIDEBAR_MAPPING } from '../../../common/metadata';
import SidebarLinkGroup from './SidebarLinkGroup';
import { UserProfileContext } from '../../../store/context/profile.context';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const profileContext = useContext(UserProfileContext);

  const { profileInfo = {} } = profileContext;
  const user = profileContext?.profileInfo?.accountname;
  const [sidebarExpanded, setSidebarExpanded] = useState(
    // updated to false to check this property of sidebar to show as false
    // storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'false',
  );

  // close on click outside
  useEffect(() => {
    console.log(user, 'user');
    console.log(profileInfo, 'profileInfo');
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };

    document.addEventListener('click', clickHandler);

    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const handleCloseSidebar = () => {
    setTimeout(() => {
      setSidebarExpanded(false);
    }, 2000);
  };

  const filteredSidebarMapping = SIDEBAR_MAPPING.filter((menu) => {
    if (
      user === 'team@haix.ai' ||
      user === 'Raj@haix.ai' ||
      user === 'Sales@haix.ai'
    ) {
      return true; // don't remove any routes for this user
    } else {
      return menu.label != 'Admin'; // remove the 'Admin' route for all other users
    }
  });
  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform
           h-[100vh]
         overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:sidebar-expanded:!w-64 shrink-0 bg-slate-800 p-4 transition-all  duration-500 ease-in-out ${
           sidebarOpen ? 'translate-x-0' : '-translate-x-64'
         }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <span className="material-icons">arrow_back</span>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            <img
              src="/avatars/HAIXWhiteLogo.png"
              className="lg:hidden lg:sidebar-expanded:inline-flex ml-2 h-auto w-24"
              alt="logo"
            />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
            </h3>
            <ul className="mt-3">
              {filteredSidebarMapping.map((menu) => {
                // if (
                //   menu.label == 'Utility Functions' &&
                //   user == 'team@haix.ai'
                // ) {
                //   return null;
                // }

                return menu.child ? (
                  <SidebarLinkGroup
                    key={menu.id}
                    // activecondition={matchPath(
                    //   pathname,
                    //   resolvePath(menu.route).pathname
                    // )}
                    label={menu.label}
                    profileInfo={profileInfo}
                    link={menu.route}
                  >
                    {(handleClick, open) => {
                      if (
                        menu.label == 'HaiX AI Assistant' &&
                        (!profileInfo?.GenAISocialInsights
                          ?.subscription_requested ||
                          profileInfo?.GenAISocialInsights
                            ?.subscription_status != 'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Real Time Social Listening Insights' &&
                        (!profileInfo?.SocialListening
                          ?.subscription_requested ||
                          profileInfo?.SocialListening?.subscription_status !=
                            'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Lead Generation Insights' &&
                        (!profileInfo?.LeadIQInsights?.subscription_requested ||
                          profileInfo?.LeadIQInsights?.subscription_status !=
                            'Approved')
                      ) {
                        return null;
                      }
                      // else if (
                      //   menu.label == "PortFolio/Competitor Insights" &&
                      //   !profileInfo?.CompetitorIntelligence
                      //     ?.subscription_requested
                      // ) {
                      //   return null;
                      // }
                      else if (
                        menu.label == 'LinkedIn Insights' &&
                        (!profileInfo?.LinkedInSocialListening
                          ?.subscription_requested ||
                          profileInfo?.LinkedInSocialListening
                            ?.subscription_status != 'Approved') &&
                        (!profileInfo?.LinkedInOwnAccountIntelligence
                          ?.subscription_requested ||
                          profileInfo?.LinkedInOwnAccountIntelligence
                            ?.subscription_status != 'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Company Intelligence' &&
                        (!profileInfo?.CompanyMarketIntelligence
                          ?.subscription_requested ||
                          profileInfo?.CompanyMarketIntelligence
                            ?.subscription_status != 'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Alerts' &&
                        (!profileInfo?.Alerts?.subscription_requested ||
                          profileInfo?.Alerts?.subscription_status !=
                            'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Gen AI Insights' &&
                        (!profileInfo?.GenAiInsights?.subscription_requested ||
                          profileInfo?.GenAiInsights?.subscription_status !=
                            'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Utility Functions' &&
                        (!profileInfo?.UtilityFunctions
                          ?.subscription_requested ||
                          profileInfo?.UtilityFunctions?.subscription_status !=
                            'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Upload PDF' &&
                        (!profileInfo?.UploadPDF?.subscription_requested ||
                          profileInfo?.UploadPDF?.subscription_status !=
                            'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Gen AI social media search' &&
                        (!profileInfo?.GenAiSocialMediaSearch
                          ?.subscription_requested ||
                          profileInfo?.GenAiSocialMediaSearch
                            ?.subscription_status != 'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Trust Pilot Insights' &&
                        (!profileInfo?.TrustpilotIntelligence
                          ?.subscription_requested ||
                          profileInfo?.TrustpilotIntelligence
                            ?.subscription_status != 'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Upload PDF' &&
                        (!profileInfo?.UploadPDF?.subscription_requested ||
                          profileInfo?.UploadPDF?.subscription_status !=
                            'Approved')
                      ) {
                        return null;
                      } else if (
                        menu.label == 'Marketing Campaign Insights' &&
                        (!profileInfo?.MarketingCampaignsMeta
                          ?.subscription_requested ||
                          profileInfo?.MarketingCampaignsMeta
                            ?.subscription_status != 'Approved')
                      ) {
                        return null;
                      } else {
                        {
                          console.log(menu.label, 'menu.label');
                        }
                        return (
                          <React.Fragment>
                            <a
                              href="#0"
                              className={`block text-slate-200 hover:text-white transition duration-150 ${'hover:text-slate-200'}`}
                              onClick={(e) => {
                                e.preventDefault();
                                sidebarExpanded
                                  ? handleClick()
                                  : setSidebarExpanded(true);
                              }}
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  <span
                                    title={menu.label}
                                    className={`sidebar-expanded:hidden  ${
                                      menu.label != 'LinkedIn Insights'
                                        ? 'material-icons'
                                        : ''
                                    }`}
                                  >
                                    {/* {menu.label != "LinkedIn Insights" &&
                                    menu.icon} */}

                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: menu.icon,
                                      }}
                                      title={menu.label}
                                    />
                                  </span>
                                  <h4 className="text-xs uppercase text-slate-500 font-semibold lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:sidebar-expanded:opacity-100 duration-200">
                                    <span className="lg:hidden lg:sidebar-expanded:block 2xl:block ">
                                      {menu.label}
                                    </span>
                                  </h4>
                                </div>
                                {/* Secction Expand/Collapse Icon*/}
                                <div className="hidden sidebar-expanded:visible sidebar-expanded:flex shrink-0 ml-2">
                                  <span className="material-icons text-slate-500 text-base">
                                    {open ? 'expand_less' : 'expand_more'}
                                  </span>
                                </div>
                              </div>
                            </a>
                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:sidebar-expanded:block">
                              <ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
                                {menu.child.map((child) => {
                                  if (
                                    child.label == 'Comparison hub' &&
                                    (!profileInfo?.CompetitorIntelligence
                                      ?.subscription_requested ||
                                      profileInfo?.CompetitorIntelligence
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  }

                                  // else if (
                                  //   child.label ===
                                  //     'LinkedIn Company Insights' &&
                                  //   (!profileInfo
                                  //     ?.LinkedInOwnAccountIntelligence
                                  //     ?.subscription_requested ||
                                  //     profileInfo
                                  //       ?.LinkedInOwnAccountIntelligence
                                  //       ?.subscription_status != 'Approved')
                                  // ) {
                                  //   return null;
                                  // }
                                  else if (
                                    child.label == 'Competitor Insights' &&
                                    (!profileInfo?.CompetitorIntelligence
                                      ?.subscription_requested ||
                                      profileInfo?.CompetitorIntelligence
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label ==
                                      'LinkedIn Public Page Insights' &&
                                    (!profileInfo?.LinkedInSocialListening
                                      ?.subscription_requested ||
                                      profileInfo?.LinkedInSocialListening
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label ==
                                      'LinkedIn Company Insights' &&
                                    (!profileInfo
                                      ?.LinkedInOwnAccountIntelligence
                                      ?.subscription_requested ||
                                      profileInfo
                                        ?.LinkedInOwnAccountIntelligence
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label == 'Lead Insights' &&
                                    (!profileInfo?.LeadIQInsights
                                      ?.subscription_requested ||
                                      profileInfo?.LeadIQInsights
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label ==
                                      'Social Media Lead Insights' &&
                                    (!profileInfo?.LeadIQInsights
                                      ?.subscription_requested ||
                                      profileInfo?.LeadIQInsights
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label == 'Trustpilot Insights' &&
                                    (!profileInfo?.TrustpilotIntelligence
                                      ?.subscription_requested ||
                                      profileInfo?.TrustpilotIntelligence
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label == 'Job Insights' &&
                                    (!profileInfo?.LeadIQInsights
                                      ?.subscription_requested ||
                                      profileInfo?.LeadIQInsights
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    (child.label == 'Facebook Ad Insights' ||
                                      child.label ==
                                        'Facebook Campaign Ad Trends' ||
                                      child.label == 'Bot Stats') &&
                                    (!profileInfo?.MarketingCampaignsMeta
                                      ?.subscription_requested ||
                                      profileInfo?.MarketingCampaignsMeta
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label == 'HaiX AI Social Insights' &&
                                    (!profileInfo?.GenAISocialInsights
                                      ?.subscription_requested ||
                                      profileInfo?.GenAISocialInsights
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label ==
                                      'Social Insights Dashboard' &&
                                    (!profileInfo?.SocialListening
                                      ?.subscription_requested ||
                                      profileInfo?.SocialListening
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label == 'Company Insights' &&
                                    (!profileInfo?.CompanyMarketIntelligence
                                      ?.subscription_requested ||
                                      profileInfo?.CompanyMarketIntelligence
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label == 'Alerts' &&
                                    (!profileInfo?.Alerts
                                      ?.subscription_requested ||
                                      profileInfo?.Alerts
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  } else if (
                                    child.label == 'Upload PDF' &&
                                    (!profileInfo?.UploadPDF
                                      ?.subscription_requested ||
                                      profileInfo?.UploadPDF
                                        ?.subscription_status != 'Approved')
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <li
                                      key={child.id}
                                      className="mb-1 last:mb-0"
                                    >
                                      <NavLink
                                        end
                                        to={`${menu.route}${child.route}`}
                                        className={`block ${
                                          window.location.pathname.startsWith(
                                            menu.route + child.route,
                                          )
                                            ? 'text-[#09c778]'
                                            : 'text-slate-400'
                                        } transition duration-150`}
                                        onClick={() => handleCloseSidebar()}
                                      >
                                        <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          {child.label}
                                        </span>
                                      </NavLink>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </React.Fragment>
                        );
                      }
                    }}
                  </SidebarLinkGroup>
                ) : (
                  <li
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                      window.location.pathname
                        .slice(1)
                        .startsWith(menu.route.slice(1)) && 'bg-slate-900'
                    }  ${!open && 'hidden'}`}
                    key={menu.id}
                  >
                    <NavLink
                      end
                      to={menu.route}
                      className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                        window.location.pathname
                          .slice(1)
                          .startsWith(menu.route.slice(1)) &&
                        'hover:text-slate-200'
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <span
                            className="sidebar-expanded:hidden material-icons"
                            title={menu.label}
                          >
                            {menu.icon}
                          </span>
                          <h4 className="text-xs uppercase text-slate-500 font-semibold lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                              {menu.label}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        {/* //this line adjusts to keep sidebar open */}
        <div className="pt-3 hidden lg:inline-flex  justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    email_verified: PropTypes.bool,
    name: PropTypes.string,
    nickname: PropTypes.string,
    picture: PropTypes.string,
    sub: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
};

export default React.memo(Sidebar);
