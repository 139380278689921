import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ChatgptContext = createContext();

export const ChatgptProvider = ({ children }) => {
  const [ChatgptData, setChatgptData] = useState(null);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [convoId, setconvoId] = useState(""); // creates unique id.
  const [PillsName, setPillsName] = useState("");
  const [socialMediaType, setSocialMediaType] = useState("");
  const [mainSearchTerm, setMainSearchTerm] = useState("");
  const [subtype, setsubtype] = useState("");
  const [SearchObject, setsearchObject] = useState({});
  const [summaryStatus,setSummaryStatus] = useState(false)

  return (
    <ChatgptContext.Provider
      value={{
        ChatgptData,
        setChatgptData,
        sidebarStatus,
        setSidebarStatus,
        setconvoId,
        convoId,
        setPillsName,
        PillsName,
        setSocialMediaType,
        socialMediaType,
        mainSearchTerm,
        setMainSearchTerm,
        subtype,
        setsubtype,
        setsearchObject,
        SearchObject,
        summaryStatus,
        setSummaryStatus
      }}
    >
      {children}
    </ChatgptContext.Provider>
  );
};

ChatgptProvider.propTypes = {
  children: PropTypes.node,
};
