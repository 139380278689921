import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Transition from "../utils/Transition";
import { useAuth0 } from "@auth0/auth0-react";

function UserMenu({ user }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const { logout } = useAuth0();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img
          className="w-8 h-8 rounded-full"
          src={user?.picture}
          width="32"
          height="32"
          alt="User"
        />
        <div className="flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium uppercase group-hover:text-slate-800">
            {user?.nickname}
          </span>
          <span className="material-icons fill-current shrink-0 ml-1 w-8 h-8 flex items-center justify-center bg-white hover:bg-slate-200 transition duration-150 rounded-full">
            expand_more
          </span>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-background border border-outline py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 bg-surface text-onsurface">
            <div className="font-medium uppercase">{user?.nickname}</div>
            <div className=" flex items-center justify-between mx-1">
              <span className="text-sm italic">{user?.email}</span>
              {user?.email_verified && (
                <span className="material-icons text-primary text-md">
                  verified
                </span>
              )}
            </div>
          </div>
          <ul>
            <li>
              <Link
                className="font-medium text-sm hover:text-onsurface hover:bg-surface flex items-center py-1 px-3"
                to="/profile"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                My Profile
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm  hover:text-onsurface hover:bg-surface flex items-center py-1 px-3"
                to="/"
                onClick={() => {
                  logout({
                    returnTo: window.location.origin,
                  });
                  localStorage.removeItem("lpi");
                  sessionStorage.removeItem("haiaitabs"
                  );
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

UserMenu.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    email_verified: PropTypes.bool,
    name: PropTypes.string,
    nickname: PropTypes.string,
    picture: PropTypes.string,
    sub: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

export default UserMenu;
