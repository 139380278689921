import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./modules/App/App";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import "./css/style.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

ReactDOM.render(
  // <React.StrictMode>
    <Router>
    <QueryClientProvider client={queryClient}>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
      </QueryClientProvider>
    </Router>
    
  // </React.StrictMode>
  ,
  document.getElementById("app")
);
